import { useState } from "react"
import { Row, Col, Container, Image, Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import Spacer from "../Spacer"
import { useRecoilState } from "recoil"
import { disabledState } from "../../atomfile"
export function SignYourContract() {
    const [price, setPrice] = useState("-");
    const [totalPrice, setTotalPrice] = useState("-")
    const [amount, setAmount] = useState(1)
    const [dis_state, setDisState] = useRecoilState(disabledState)

    function voucherOnChange(event) {
        if (event.target.value === "neofest") {
            setPrice(100)
            setTotalPrice(100 * amount)
        }
        else {
            setPrice(35)
            setTotalPrice(35 * amount)
        }
    }

    function amountOnChange(event) {
        
        if(price !== "-") {
            setTotalPrice(price * parseInt(event.target.value))
        }
        
        setAmount(parseInt(event.target.value))
    }

    var link = "/loading/" + price + "/" + amount
    return (
        <Container>
            <Row className="pt-5 mt-5 pb-5">
                <Col xs={12} md={6} >
                    <Row>
                        <Col>
                            <h1 className="sign-your-contract-header">
                                GET YOUR TEAM MEMBERSHIP VOUCHER
                            </h1>
                            <p className="sign-your-contract-subtitle">and receive your coverted Neo Lanyard.</p>
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <div className="w-25 mb-5" style={{ height: "2px", background: "black" }}></div>
                        </Col>

                    </Row>

                    <Row>
                        <Col>

                            <p>When mint opens on the 28th of February you will be able to mint your membership voucher and exchange it for a lanyard on <b>Reveal day (7th March 2022)</b>.</p>
                            <p>Until then join the <span><a href="https://discord.gg/NeoNFT">discord</a></span> and keep up to date with all the latest announcements.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p><a href="#BuildRacingTeams">Neo Championship</a> <span className="ml-2 mr-2" >|</span><a href="#RoleInformation">Your rewards</a></p>
                        </Col>
                    </Row>
                </Col>

                <Col md={6}>
                    <Row className="mt-5 mb-5">
                        <Col>
                            <Image align="middle" src="/pages/images/home/home-voucher-overlap.png" fluid></Image>
                        </Col>
                    </Row>

                    <Row align="right" className="d-none d-lg-block mx-auto">
                        <Col className="p-0" md={10}>
                            <Row>
                                <Col>
                                    <Row className="w-100 founder-reward mx-auto">
                                        <Col md={4} xs={5} className="my-auto">
                                            <Image className="pl-4" src="/pages/images/home/founder-male-50000.png" fluid>
                                            </Image>
                                        </Col>
                                        <Col className="pt-2 pl-3 pr-4 my-auto">
                                            <Row>
                                                <h1 style={{ fontSize: "20px", lineHeight: "20px" }}>1 x random nft worth <span style={{ color: "#A7AC1C" }}>$50,000</span></h1>
                                            </Row>
                                            <Row>
                                                <p align="left" style={{ fontSize: "12px", lineHeight: "14px" }}>One random reedemed NFT will be picked to be upgraded to a Founder Role, worth $50,000</p>
                                            </Row>
                                        </Col>

                                    </Row>
                                </Col>

                            </Row>

                        </Col>
                    </Row>

                    <Row className="d-md-none">
                        <Col className="p-0" md={5} xs={12}>
                            <Row style={{ marginLeft: "0", marginRight: "0" }}>
                                <Col>
                                    <Row className="w-100 founder-reward mx-auto">
                                        <Col md={4} xs={5} className="my-auto">
                                            <Image className="pl-4" src="/pages/images/home/founder-male-50000.png" fluid>
                                            </Image>
                                        </Col>
                                        <Col className="pt-2 pl-3 pr-4 my-auto">
                                            <Row>
                                                <h1 style={{ fontSize: "20px", lineHeight: "20px" }}>1 x random nft worth <span style={{ color: "#A7AC1C" }}>$50,000</span></h1>
                                            </Row>
                                            <Row>
                                                <p style={{ fontSize: "12px", lineHeight: "14px" }}>One random reedemed NFT will be picked to be upgraded to a Founder Role, worth $50,000</p>
                                            </Row>
                                        </Col>

                                    </Row>
                                </Col>
                            </Row>

                        </Col>
                    </Row>

                </Col>
            </Row>
            <Row className="pt-5 pb-5">
                <Spacer />
            </Row>
            <Row id="VoucherPurchase" className="pb-5 mb-5">
                <Col md={7}>
                    {/* this is the standard voucher row */}
                    <Row className="pb-3">
                        <Col xs={1} className="my-auto">
                            <div onChange={voucherOnChange}>
                                <input type="radio" value="standard" name="gold" id="silver" />
                            </div>
                        </Col>
                        <Col className="my-auto">
                            <label for="silver"><Image src="/pages/images/home/neo-voucher-silver.png"></Image></label>
                        </Col>
                        <Col className="my-auto">
                            <p className="p-0 m-0"><b>Silver membership | 35 FLOW</b></p>
                            <p>Neo-Fest access sold separately</p>
                        </Col>
                    </Row>

                    {/* this is the neo fest row */}
                    <Row className="pb-4">
                        <Col xs={1} className="my-auto">
                            <div onChange={voucherOnChange}>
                                <input type="radio" value="neofest" name="gold" id="gold" />
                            </div>
                        </Col>
                        <Col className="my-auto">
                            {/* <div onChange={setPrice(100)}><input type="radio" value="Male" name="gender" /> Male</div> */}
                            <label for="gold"><Image src="/pages/images/home/neo-voucher-gold.png"></Image></label>
                        </Col>
                        <Col className="my-auto">
                            <p className="p-0 m-0"><b>Gold membership | 100 FLOW</b></p>
                            <p>Neo-Fest access included (3 Years)</p>
                        </Col>
                    </Row>

                </Col>
                <Col xs={12} md={5}>
                    <Row className="justify-content-end">
                        <Col align="right">
                            <p>Total supply: 660</p>
                        </Col>

                    </Row>
                    <Row>
                        <Col md={12} align="right">
                        <p>Quantity:</p>
                        </Col>
                    </Row>
                    <Row>
                    <Col md={12} align="right">
                            <select className="w-25" value={amount} onChange={amountOnChange}  >
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                            </select>
                        </Col>
                    </Row>
                    <Row className="justify-content-end">
                        <Col align="right">
                            <h1>{totalPrice} FLOW</h1>
                        </Col>

                    </Row>
                    <Row className="justify-content-end">
                        {price === "-" &&
                            <Col xs={12} md={6} align="right">
                                <Link to={link}><Button className="w-100" disabled={dis_state} variant="dark">Mint a voucher</Button></Link>
                            </Col>
                        }
                        {price !== "-" &&
                            <Col xs={12} md={6} align="right">
                                <Link to={link}><Button className="w-100" disabled={dis_state} variant="dark">Mint a voucher</Button></Link>
                            </Col>
                        }

                    </Row>
                </Col>
            </Row>
        </Container>
        // <Container>
        //     <Row className='pb-5 mb-5 mt-5'>

        //     </Row>
        //     <Container>
        //         {/* DESKTOP */}
        //         <Row className="d-lg-flex d-none">
        //             <Col md={5}>
        //                 <Row>
        //                     <h1 className="sign-your-contract-header">GET YOUR RACE DAY PASS</h1>
        //                     <p className="sign-your-contract-subtitle pr-5 mr-5">and receive your coveted Neo Lanyard.</p>

        //                 </Row>
        //                 <Row>
        //                     <div className="w-25 mb-5" style={{ height: "2px", background: "black" }}></div>
        //                 </Row>
        //                 <Row>
        //                     <p>When mint opens on the 28th of February you will be able to mint your membership and use it to exchange for a lanyard on <b>Exchange day (7th March 2022)</b>.</p>

        //                     <p>Until then join the <span><a href="https://discord.gg/NeoNFT">discord</a></span> and keep up to date with all the latest announcements.</p>
        //                 </Row>
        //                 <Row className="pt-5">
        //                 </Row>
        //             </Col>
        //             <Col md={1}>
        //             </Col>
        //             <Col md={6}>
        //                 <Row>
        //                     <Image src="/pages/images/home/member-contract-image.png">

        //                     </Image>
        //                 </Row>
        //             </Col>
        //         </Row>
        //         <Row className="d-lg-flex d-none">
        //             <Col className="p-0" md={4}>
        //                 <a href="#BuildRacingTeams">How does it work?</a> | <a href="#RoleInformation">Your rewards</a>
        //             </Col>
        //             <Col md={2}>
        //             </Col>
        //             <Col md={6}>
        //                 <Row>
        //                     <p>Race organisers: <span><Image src="/pages/images/home/neo-signature.png"></Image></span></p>
        //                     <div className="offset-sign-your-contract pl-5 ">
        //                         <p className="p-0 m-0 pt-2" align="right">Total supply: 660</p>
        //                         <h1  align="right">100 FLOW</h1>
        //                         <div align="center" style={{zIndex: "1"}}><Link to='/loading'><Button variant="dark" style={{zIndex: "1"}}>Get a race pass</Button></Link></div>
        //                         {/* <Button onClick={handleBuyvoucher} variant="dark">Get a race pass</Button> */}
        //                     </div>

        //                 </Row>

        //             </Col>
        //         </Row>

        //         {/* DESKTOP */}
        // <Row className="d-none d-lg-block">
        //     <Col className="p-0" md={5}>
        //         <Row>
        //             <Col>
        //                 <Row className="w-100 founder-reward mx-auto">
        //                     <Col md={4} xs={5} className="my-auto">
        //                         <Image className="pl-4" src="/pages/images/home/founder-male-50000.png" fluid>
        //                         </Image>
        //                     </Col>
        //                     <Col className="pt-2 pl-3 pr-4 my-auto">
        //                         <Row>
        //                             <h1 style={{ fontSize: "20px", lineHeight: "20px" }}>1 x random nft worth <span style={{ color: "#A7AC1C" }}>$50,000</span></h1>
        //                         </Row>
        //                         <Row>
        //                             <p style={{ fontSize: "12px", lineHeight: "14px" }}>One random reedemed NFT will be picked to be upgraded to a Founder Role, worth $50,000</p>
        //                         </Row>
        //                     </Col>

        //                 </Row>
        //             </Col>

        //         </Row>

        //     </Col>
        // </Row>


        //         {/* MOBILE */}

        //         <Row className="d-lg-none">
        //             <Col md={12} xs={12}>
        //                 <Row>
        //                     <h1 className="sign-your-contract-header">GET YOUR RACE DAY PASS</h1>
        //                     <p className="sign-your-contract-subtitle ">and receive your coveted Neo Lanyard.</p>

        //                 </Row>
        //                 <Row>
        //                     <div className="w-25 mb-5" style={{ height: "2px", background: "black" }}></div>
        //                 </Row>

        //                 <Row>
        //                     <Image className="mx-auto" src="/pages/images/home/member-contract-image.png" fluid>

        //                     </Image>
        //                 </Row>
        //                 <Row>
        //                     <Col md={12}>
        //                         <p align="right">Total supply: 660</p>
        //                         <h1 style={{ fontSize: "50px" }} align="right">100 FLOW</h1>
        //                         <Button className="w-100" variant="dark">Get a race pass</Button>

        //                         <Row className="pt-4" >
        //                             <p>Race organisers: <span><Image src="/pages/images/home/neo-signature.png"></Image></span></p>
        //                         </Row>

        //                     </Col>
        //                 </Row>

        //                 <Row>
        //                     <p>When mint opens on the 28th of February you will be able to mint your membership and use it to exchange for a lanyard on <b>Exchange day (7th March 2022)</b></p>

        //                     <p>Until then join the <span><a href="https://discord.gg/NeoNFT">discord</a></span> and keep up to date with all the latest announcements.
        //                     </p>
        //                 </Row>
        //                 <Row className="pt-2">
        //                     <p><a href="#BuildRacingTeams">How does it work?</a> | <a href="#RoleInformation">Your rewards</a></p>
        //                 </Row>
        //             </Col>
        //             <Col md={1}>
        //             </Col>

        //         </Row>

        //         {/* MOBILE  */}
        // <Row className="d-md-none pt-5">
        //     <Col md={5}>
        //         <Row>
        //             <Col>
        //                 <Row className="w-100 founder-reward mx-auto">
        //                     <Col md={4} xs={5} className="my-auto">
        //                         <Image className="pl-4" src="/pages/images/home/founder-male-50000.png" fluid>
        //                         </Image>
        //                     </Col>
        //                     <Col className="pt-2 pl-3 pr-4 my-auto">
        //                         <Row>
        //                             <h1 style={{ fontSize: "20px", lineHeight: "20px" }}>1 x random nft worth <span style={{ color: "#A7AC1C" }}>$50,000</span></h1>
        //                         </Row>
        //                         <Row>
        //                             <p style={{ fontSize: "12px", lineHeight: "14px" }}>One random reedemed NFT will be picked to be upgraded to a Founder Role, worth $50,000</p>
        //                         </Row>
        //                     </Col>

        //                 </Row>
        //             </Col>

        //         </Row>

        //     </Col>
        // </Row>

        //     </Container>
        //     <Row className='pb-4 mb-4 mt-5'>

        //     </Row>
        // </Container>
    )
}