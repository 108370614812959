import { Row, Col} from "react-bootstrap"
import Countdown from 'react-countdown';
import { useRecoilState } from "recoil";
import { disabledState } from "../../atomfile";



export function CountdownTimer() {
    const [dis_state, setDisState] = useRecoilState(disabledState)
    function CountdownTimerRenderer({ days, hours, minutes, seconds, completed }) {
        if (completed) {
            setDisState(false)
            return (<div className="w-100">
                <div style={{ position: "relative", marginBottom: "-80px" }} className=" d-none d-md-block">
                    {/* DESKTOP */}
                    <Row className="mx-auto">
                        <Row className="p-0 m-0 mt-5 pb-3 pt-4 p-4 pr-5 pl-5 countdown-timer mx-auto">
                            <h1 className="my-auto">the mint is live</h1>

                        </Row>

                    </Row>

                </div>
                {/* MOBILE */}
                <div align="center" style={{ position: "relative", marginBottom: "-50px" }} className="d-md-none mx-auto w-100">

                    <Row align="center" className="p-0 m-0 ml-5 mr-3 p-3  countdown-timer">
                        <Row className="mx-auto mt-2">
                            <h1 className="my-auto">the mint is live</h1>
                        </Row>

                    </Row>
                </div>


            </div>)
        }
        else {
            return (
                // Render a countdown
                <div className="w-100">
                    <div style={{ position: "relative", marginBottom: "-80px" }} className="d-md-block d-none">
                        {/* DESKTOP */}
                        <Row className="mx-auto">
                            <Row className="p-0 m-0 mt-5 pb-3 pt-4 p-4 pr-5 pl-5 countdown-timer mx-auto">
                                <Col align="center" className="my-auto p-0">
                                    <p className="countdown-timer-p p-0 mb-1">{days}</p>
                                    <p className="units p-0 mb-1">DAYS</p>
                                </Col>
                                <Col align="center">
                                    <p className="countdown-timer-p p-0 mb-1">:</p>
                                </Col>
                                <Col align="center" className="my-auto p-0">
                                    <p className="countdown-timer-p p-0 mb-1">{hours}</p>
                                    <p className="units p-0 mb-1">HOURS</p>
                                </Col>
                                <Col align="center">
                                    <p className="countdown-timer-p p-0 mb-1">:</p>
                                </Col>
                                <Col align="center" className="my-auto p-0">
                                    <p className="countdown-timer-p p-0 mb-1">{minutes}</p>
                                    <p className="units p-0 mb-1">MINS</p>
                                </Col>
                                <Col>
                                    <p className="countdown-timer-p p-0 mb-1">:</p>
                                </Col>
                                <Col align="center" className="my-auto p-0">
                                    <p className="countdown-timer-p p-0 mb-1">{seconds}</p>
                                    <p className="units p-0 mb-1">SECS</p>
                                </Col>

                            </Row>

                        </Row>

                    </div>
                    {/* MOBILE */}
                    <div align="center" style={{ position: "relative", marginBottom: "-50px" }} className="d-md-none mx-auto w-100">

                        <Row align="center" className="p-0 m-0 ml-5 mr-3 p-3  countdown-timer">
                            <Row className="mx-auto mt-2">
                                <Col align="center" className="my-auto p-0">
                                    <p className="countdown-timer-p-xs p-0 mb-1">{days}</p>
                                    <p className="units p-0 mb-1">DAYS</p>
                                </Col>
                                <Col align="center">
                                    <p className="countdown-timer-p p-0 mb-1">:</p>
                                </Col>
                                <Col align="center" className="my-auto p-0">
                                    <p className="countdown-timer-p-xs p-0 mb-1">{hours}</p>
                                    <p className="units p-0 mb-1">HOURS</p>
                                </Col>
                                <Col align="center">
                                    <p className="countdown-timer-p p-0 mb-1">:</p>
                                </Col>
                                <Col align="center" className="my-auto p-0">
                                    <p className="countdown-timer-p-xs p-0 mb-1">{minutes}</p>
                                    <p className="units p-0 mb-1">MINS</p>
                                </Col>
                                <Col align="center">
                                    <p className="countdown-timer-p p-0 mb-1">:</p>
                                </Col>
                                <Col align="center" className="my-auto p-0">
                                    <p className="countdown-timer-p-xs p-0 mb-1">{seconds}</p>
                                    <p className="units p-0 mb-1">SECS</p>
                                </Col>
                            </Row>

                        </Row>
                    </div>


                </div>);
        }
    }
        return <Countdown
        date={new Date(1646078400 * 1000).toUTCString()}
        renderer={CountdownTimerRenderer}
      />

    // return <Countdown
    // date={Date.now()}
    //     renderer={CountdownTimerRenderer}
    // />
}