import React from "react";
import Marquee from "react-fast-marquee";

export function MovingBanner(props) {
    if(props.nft)
    {
        return(
            <Marquee className="moving-banner" speed={80}>
                <div className="pl-5 pr-5"><p className="moving-banner-p">1 x neo nft will be worth<span style={{color: '#DDE32C' }}> $50,000</span></p></div>
                <div className="pl-5 pr-5"><p className="moving-banner-p">1 x neo nft will be worth<span style={{color: '#DDE32C' }}> $50,000</span></p></div>
                <div className="pl-5 pr-5"><p className="moving-banner-p">1 x neo nft will be worth<span style={{color: '#DDE32C' }}> $50,000</span></p></div>
                <div className="pl-5 pr-5"><p className="moving-banner-p">1 x neo nft will be worth<span style={{color: '#DDE32C' }}> $50,000</span></p></div>
                <div className="pl-5 pr-5"><p className="moving-banner-p">1 x neo nft will be worth<span style={{color: '#DDE32C' }}> $50,000</span></p></div>
                <div className="pl-5 pr-5"><p className="moving-banner-p">1 x neo nft will be worth<span style={{color: '#DDE32C' }}> $50,000</span></p></div>
        </Marquee>
        ) 
        
    }
    else {
        return(
            <Marquee className="moving-banner">
            <div className="pl-5 pr-5"><p className="moving-banner-p"><span style={{color: '#DDE32C' }}>NEO</span> CHAMPIONSHIPS PRESEASON - 21.02.2022</p></div>
            <div className="pl-5 pr-5"><p className="moving-banner-p"><span style={{color: '#DDE32C' }}>NEO</span> CHAMPIONSHIPS PRESEASON - 21.02.2022</p></div>
            <div className="pl-5 pr-5"><p className="moving-banner-p"><span style={{color: '#DDE32C' }}>NEO</span> CHAMPIONSHIPS PRESEASON - 21.02.2022</p></div>
            <div className="pl-5 pr-5"><p className="moving-banner-p"><span style={{color: '#DDE32C' }}>NEO</span> CHAMPIONSHIPS PRESEASON - 21.02.2022</p></div>
            <div className="pl-5 pr-5"><p className="moving-banner-p"><span style={{color: '#DDE32C' }}>NEO</span> CHAMPIONSHIPS PRESEASON - 21.02.2022</p></div>
            <div className="pl-5 pr-5"><p className="moving-banner-p"><span style={{color: '#DDE32C' }}>NEO</span> CHAMPIONSHIPS PRESEASON - 21.02.2022</p></div>
    </Marquee>
        )
    }
  
}