import { ContainsField } from "faunadb"
import { Row, Col, Image, Container } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import "../MemberDetails/MemberDetails.css"
import { tabdata } from "../Mint/TabContent/tabdata"
import { getTeamData } from "../RevealStandard/faunaFunctions"
import { useState } from "react"
import { useEffect } from "react"

//TODO: Get the member background colour and decal from fauna
function MemberKeyDetails({ profile }) {
    const [teamData, setTeamData] = useState([])
    const navigate = useNavigate();
    const { nftID } = useParams()
    let data = profile.members
    let cardRole
    let image
    let thumbnail
    const refs = {
        "1": "324972442427588802",
        "2": "324972450177614018",
        "3": "324972454388695234",
        "4": "324972457592094914",
        "5": "324972461489651906",
        "6": "324972464326049986",
        "7": "324972468539228354",
        "8": "324972473120456898",
        "9": "324972476376285378",
        "10": "324972479319638210"
    }
    data = data.filter(item => item.id === parseInt(nftID))
    useEffect(() => {
        getTeamData(refs[data[0].teamId]).then(res => setTeamData(res))
    }, [data])

    if (data.length === 0 || !data[0]) {
        return (<Container><h1>NOTHING FOUND</h1></Container>)
    }
    cardRole = data[0]?.role
    if (cardRole) {
        cardRole = cardRole.replace(/\s+/g, '').toLowerCase();
    }
    if (data[0].mediaHash === "" || data[0].mediaHash === "DUMMY HASH" || data[0].mediaHash === "QmeM7CT9aLqakZUy51pBzf59NYe9yW7wS7Wa6rpDpo3aPN" || data[0].mediaHash === "QmRNPLnph6L7XB232DHiSBMvtRthivz6M8cmYreyyUKyjj" || data[0].mediaHash === "QmbpDjruX7dXw3Asy3FzvifwxjQuPcfVMfD5FEYEPtqHjJ" || data[0].mediaHash === "Qmberb1s1AYafWSoq8JsRw3PydVZx9ffLU6h1gqZHtc9To") {
        if (data[0].mediaHash === "" || data[0].mediaHash === "DUMMY HASH") {
            image = tabdata[cardRole].placeholder
            thumbnail = tabdata[cardRole].placeholder
        } else {
            image = "https://ipfs.neocollectibles.xyz/ipfs/" + data[0].mediaHash
            thumbnail = "https://ipfs.neocollectibles.xyz/ipfs/" + data[0].thumbnailHash
        }

    } else {
        image = "https://ipfs.neocollectibles.xyz/ipfs/" + data[0].mediaHash
        thumbnail = "https://ipfs.neocollectibles.xyz/ipfs/" + data[0].thumbnailHash
    }

    console.log(teamData)
    if (teamData.length != 0) {

        return (
            <div>
                <div className="backgroundMD d-none d-md-flex">
                    <Row className="h-100 w-100 mx-auto d-none d-md-flex">
                        <Col xs={2}>
                        </Col>
                        <Col className="my-auto pr-0">
                            <Row className="" style={{marginTop: "-100px"}}>
                                <Col className="my-auto pr-0">

                                    <div className="w-100" style={{ marginTop: "100px", backgroundColor: "white", borderRadius: "16px" }}>

                                        <Row className="h-100 w-100 ">
                                            <Col xs={1} className="">
                                                <div style={{ backgroundColor: "rgb(" + teamData.data[data[0].role][data[0].edition]["background_colour"][0] + "," + teamData.data[data[0].role][data[0].edition]["background_colour"][1] + "," + teamData.data[data[0].role][data[0].edition]["background_colour"][2], width: "25px", borderRadius: "16px 0px 0px 16px" }} className="h-100">

                                                </div>
                                            </Col>
                                            <Col xs={5} >
                                                {/* <div style={{ backgroundColor: "green", height: "300px", maxWidth: "1000px" }}>

                                    </div> */}
                                                <Row style={{marginTop: "-50px", marginBottom: "-50px"}}>
                                                    <Image fluid style={{ borderRadius: "16px" }} src={image}>

                                                    </Image>
                                                </Row>

                                            </Col>
                                            <Col className="ml-5 mt-4 mb-4 my-auto">
                                                <div>
                                                    <h1>{data[0].role}</h1>
                                                    <p>Owned by {profile.profile.findName ? <a href={"https://find.xyz/" + profile.profile.findName} target="_blank">{profile.profile.findName}.find</a> : profile.profile.address}</p>
                                                    <p>Team: <span><b>{data[0].teamId}</b></span></p>
                                                    <p>Edition in team: <span><b>{data[0].edition}</b></span></p>
                                                    <p>Background colour: <span><b>{teamData.data[data[0].role][data[0].edition]["background_colour"][0]},{teamData.data[data[0].role][data[0].edition]["background_colour"][1]},{teamData.data[data[0].role][data[0].edition]["background_colour"][2]} </b></span></p>
                                                    <p>Decal: <span><b>{teamData.data[data[0].role][data[0].edition]["decal"]}</b></span></p>
                                                </div>
                                            </Col>

                                        </Row>
                                    </div>
                                </Col>
                            </Row>

                        </Col>
                        <Col xs={2} className="">
                        </Col>
                    </Row>
                </div>
                <div className="backgroundMDm d-md-none">
                    <Row className="h-100 w-100 mx-auto d-md-none">
                        <Col className="my-auto">
                            <Row className="mt-5 mx-auto">
                                <Col >
                                    {/* <div style={{ backgroundColor: "green", height: "300px", maxWidth: "1000px" }}>

                                    </div> */}
                                    <Image fluid style={{ borderRadius: "16px" }} className="h-100 mx-auto" src={image}>

                                    </Image>
                                </Col>
                            </Row>
                            <Row style={{ height: "400px" }} className="">
                                <Col className="my-auto">

                                    <div className="w-100" style={{ backgroundColor: "white", height: "340px", borderRadius: "16px" }}>

                                        <Row className="h-100 w-100 ">
                                            <Col xs={1} className="">
                                                <div style={{ backgroundColor: "rgb(" + teamData.data[data[0].role][data[0].edition]["background_colour"][0] + "," + teamData.data[data[0].role][data[0].edition]["background_colour"][1] + "," + teamData.data[data[0].role][data[0].edition]["background_colour"][2], maxHeight: "340px", width: "25px", borderRadius: "16px 0px 0px 16px" }} className="h-100">

                                                </div>
                                            </Col>

                                            <Col className="ml-4 mt-4 mb-4">
                                                <div>
                                                    <h1>{data[0].role}</h1>
                                                    <p>Owned by {profile.profile.findName ? <a href={"https://find.xyz/" + profile.profile.findName} target="_blank">{profile.profile.findName}.find</a> : profile.profile.address}</p>
                                                    <p>Team: <span><b>{data[0].teamId}</b></span></p>
                                                    <p>Edition in team: <span><b>{data[0].edition}</b></span></p>
                                                    <p>Background colour: <span><b>{teamData.data[data[0].role][data[0].edition]["background_colour"][0]},{teamData.data[data[0].role][data[0].edition]["background_colour"][1]},{teamData.data[data[0].role][data[0].edition]["background_colour"][2]} </b></span></p>
                                                    <p>Decal: <span><b>{teamData.data[data[0].role][data[0].edition]["decal"]}</b></span></p>
                                                </div>
                                            </Col>

                                        </Row>
                                    </div>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </div>
            </div>

        )
    }
    else {
        return (<p>Loading</p>)
    }
}
export default MemberKeyDetails

