import { useEffect, useState } from "react";
import { Col, Row, ProgressBar, Button, Image } from "react-bootstrap";
import './LoadingNft.css';
import * as fcl from "@onflow/fcl";
import { Tx } from "../../functions/transaction";
import * as t from "@onflow/types";
import { transactions } from 'neo-flow-contracts'
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";


export function LoadingNft({neoFest}) {
  //const [loadingProgress, setLoadingProgress] = useState(0)
  const [errorState, setErrorState] = useState("")
  const [loadingText, setLoadingText] = useState("")
  const [loadingSubtext,setLoadingSubtext] = useState("we are just claiming your NFT - this may take a few moments")
  const [loadProgress, setLoadProgress] = useState(0)
  
  var imageUrl;

  //const navigate = useNavigate()
  var { voucherChoice,  amountOfVouchers} = useParams()

  if(voucherChoice === "35") {
    imageUrl = "https://ipfs.neocollectibles.xyz/ipfs/QmS5RYF1HpUp6E7ueFuXpYW8nEaqTuRLidLdiQ1HKrxsTu"
  }
  else if(voucherChoice === "100") {
    imageUrl = "https://ipfs.neocollectibles.xyz/ipfs/QmZPNktx8bGyY3phFSmgf9qpdYE1KK8oXrcwVThLswqY8f"
  }

  voucherChoice = parseFloat(voucherChoice).toFixed(2);
  amountOfVouchers = parseInt(amountOfVouchers)
  var totalAmount = voucherChoice * amountOfVouchers
  totalAmount = parseFloat(totalAmount).toFixed(2);

  const refreshPage = ()=>{
    window.location.reload();
 }

   const handleBuyvoucher = async (e) => {
    try {
      await Tx(
        [
          fcl.transaction(transactions.buyNeoVoucherBulk),
          fcl.args([
            fcl.arg(totalAmount, t.UFix64),
            fcl.arg(voucherChoice, t.UFix64),
            fcl.arg(process.env.REACT_APP_MARKETPLACE_ADDRESS, t.Address),
            fcl.arg(amountOfVouchers, t.Int),
          ]),
          fcl.proposer(fcl.currentUser().authorization),
          fcl.payer(fcl.currentUser().authorization),
          fcl.authorizations([fcl.currentUser().authorization]),
          fcl.limit(9999),
        ],
        {
          onStart() {
            setLoadProgress(33)
            setLoadingText("Waiting for transaction")
            setLoadingSubtext("Please approve the transaction.")
            setErrorState(null);
          },
          onSubmission() {
            setLoadProgress(66)
            setLoadingText("Submitted.")
            setLoadingSubtext("Your voucher will be with you very soon.")
          },
          async onSuccess(status) {
            setLoadProgress(100)
            setLoadingText("Success!")
            setLoadingSubtext("Your voucher is here - check it out!")
          },
          async onError(error) {
            if (error) {
              const { message } = error;
              setErrorState(error);
              setLoadingText("ERROR")
            }
          },
        }
      );
    } catch (e) {
      console.log(e);
    }
  }

    useEffect(() => {
      handleBuyvoucher();
     },[]);



    // useEffect(() => {
    //     console.log(JSON.stringify(latestMessage, null, 2))
    // },[latestMessage]
    // )

    // let conn = useRef();
    // useEffect(() => {
    //     const streamSDK = new GraffleSDK();
    //     const feed = async (message) => {
    //         console.log()
    //         //message received, do something (need to filter)
    //         if (message.flowEventId === "A.60fcd8cb556cf57f.NeoVoucher.Withdraw") {
    //             setLoadProgress(33)
    //             setVoucherIdentifier(message.blockEventData.id)
    //             console.log(message.blockEventData.id +" is the same as " +voucherIdentifier)
    //         }
    //         if (message.flowEventId === "A.60fcd8cb556cf57f.NeoVoucher.Purchased" && message.blockEventData.id === voucherIdentifier) {
    //             setLoadProgress(66)
    //         }
    //         if (message.flowEventId === "A.60fcd8cb556cf57f.NeoVoucher.Deposit" ) {
    //             setLoadProgress(100)
    //         }
            

    //         setLatestMessage(message);
    //         console.log(message);

    //     };
    //     async function startConn() {
    //         //console.log("Creating the stream")
    //         conn.current = await streamSDK.stream(feed);
    //     }
    //     startConn()
    // }, []);
    // useEffect(() => () => {
    //     //console.log("Stopping the connection")
    //     conn.current.stop()
    // }, []);

    // console.log(JSON.stringify(latestMessage.blockEventData.to) + " is the same as " + props.id)

    // if(JSON.stringify(latestMessage.blockEventData) === props.id)
    // {
    //     setReveal(true)
    // }
    // else
    // {
    //     setReveal(false)
    // }
    
    if(errorState === null) {
    if(loadProgress !== 100)
    {
    return (
        <div className="LoadingBG p-3">
            <div className="LoadingNft p-3" align='center'>
                <Row className="mt-4 mb-3">
                    <Col>
                    <h1>{loadingText}</h1>
                    <p>{loadingSubtext}</p>
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col className="px-5">
                        <Image src='/pages/images/Loading/neo-bike-small.webp' fluid />
                        <ProgressBar variant="success" animated now={loadProgress} />

                    </Col>
                </Row>
                <Row className="mx-3 mb-5">
                    <Col className="my-auto" xs='12' lg='6'>Wanna do something whilst you wait?</Col>
                    <Col xs='12' lg='6' className="mt-3 mt-lg-0">
                        <a href='https://discord.gg/neonft' target="_blank"><Button variant='dark'>Join our discord</Button></a>
                    </Col>
                </Row>
            </div>
        </div>
    )
    }
    else {
      return(
        <div className="LoadingBG p-3">
            <div className="LoadingNft p-3" align='center'>
                <Row className="mt-4 mb-3">
                    <Col>
                    <h1>{loadingText}</h1>
                    <p>{loadingSubtext}</p>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col className="px-5">
                        <Image src={imageUrl} fluid />
                    </Col>
                    
                </Row>
                <p>You will now see your voucher in your <Link to="/profile">profile</Link></p>
            </div>
        </div>
      )
    }
  }
  else {
    return (
      <div className="LoadingBG p-3">
            <div className="LoadingNft p-3" align='center'>
                <Row className="mt-4 mb-3">
                    <Col>
                    <h1>{loadingText}</h1>
                    <p>{errorState}</p>
                    <p><Link to="/loading" onClick={refreshPage}>Refresh</Link> the page and try again</p>
                    </Col>
                </Row>
            </div>
        </div>
    )
  }
}