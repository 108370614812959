import { Container, Row, Col, Image, Button, Carousel } from "react-bootstrap";
import { disabledState } from "../../atomfile";
import { useRecoilValue } from "recoil"

export function BuildRacingTeams() {

  const dis_state = useRecoilValue(disabledState)
  return (
    <div>
      
    
    <Container fluid id="BuildRacingTeams" className="pb-3">
    <Row id="BuildRacingTeams" className='pb-5 pt-5'>

</Row>
      <Container>
        <Row>
          <Col className="m-md-5 m-3 text-center">
            <h1 className="BuildRacingTeams-h1">A CHAMPIONSHIP FOR EVERYONE</h1>
          </Col>
        </Row>
        <Row className="d-lg-flex justify-content-center d-none">
          <Col md="8" lg="3">
            <Row>
              <Col className="p-0 m-0" style={{ height: "120px" }}><Image className="mx-auto d-block" src="/pages/images/home/Feature-Icon-with-circle.webp" fluid /></Col>
            </Row>
            <Row>
              <Col className="text-center pb-1"><span className="BuildCardsBold">Teams</span></Col>
            </Row>
            <Row>
              <Col className="text-center px-4"><p className="BuildRacingTeams-p">Build a community of NFT collectors and compete, challlenge and more to win rewards</p></Col>
            </Row>
          </Col>
          <Col lg="3">
            <Row>
              <Col className="p-0 pt-2 m-0" style={{ height: "120px" }}><Image className="mx-auto d-block mt-1 pt-1" style={{ height: "90px" }} src="/pages/images/home/Feature-Icon-roles.webp" /></Col>
            </Row>
            <Row>
              <Col className="text-center pb-1"><span className="BuildCardsBold">Roles</span></Col>
            </Row>
            <Row>
              <Col className="text-center px-5"><p className="BuildRacingTeams-p">Get the chance of receiving one of 4 roles within a team all for the same mint price</p></Col>
            </Row>
          </Col>
          <Col lg="3">
            <Row>
              <Col className="p-0 m-0" style={{ height: "120px" }}><Image className="mx-auto d-block" src="/pages/images/home/Feature-Icon-rewards.webp" fluid /></Col>
            </Row>
            <Row>
              <Col className="text-center pb-1"><span className="BuildCardsBold">Rewards</span></Col>
            </Row>
            <Row>
              <Col className="text-center px-5"><p className="BuildRacingTeams-p">Get multiple rewards by owning a NFT role, as well as team rewards</p></Col>
            </Row>
          </Col>
          <Col lg="3">
            <Row>
              <Col className="p-0 align-items-center d-flex" style={{ height: "120px" }}><Image className="mx-auto d-block" src="/pages/images/home/Feature-Icon-more.webp" fluid /></Col>
            </Row>
            <Row>
              <Col className="text-center pb-1"><span className="BuildCardsBold">More</span></Col>
            </Row>
            <Row>
              <Col className="text-center px-5"><p className="BuildRacingTeams-p">Being part of NEO means you get acces to NEO-Fest aswell as other cool rewards</p></Col>
            </Row>
          </Col>
        </Row>
        <Row className="d-sm-block d-lg-none">
          <Col>
            <Carousel indicators={false}>
              <Carousel.Item>
                <Col>
                  <Row>
                    <Col className="p-0 m-0" style={{ height: "120px" }}><Image className="mx-auto d-block" src="/pages/images/home/Feature-Icon-with-circle.webp" fluid /></Col>
                  </Row>
                  <Row>
                    <Col className="text-center pb-1"><span className="BuildCardsBold">Teams</span></Col>
                  </Row>
                  <Row>
                    <Col className="text-center px-4"><p className="BuildRacingTeams-p">Build a team of fellow NFT collectors and compete, challlenge and more to win rewards</p></Col>
                  </Row>
                </Col>
              </Carousel.Item>
              <Carousel.Item>
                <Col>
                  <Row>
                    <Col className="p-0 pt-2 m-0" style={{ height: "120px" }}><Image className="mx-auto d-block" style={{ height: "90px" }} src="/pages/images/home/Feature-Icon-roles.webp" fluid /></Col>
                  </Row>
                  <Row>
                    <Col className="text-center pb-1"><span className="BuildCardsBold">Roles</span></Col>
                  </Row>
                  <Row>
                    <Col className="text-center px-5"><p className="BuildRacingTeams-p">Get the chance of recieving one of 4 roles within a team for mandatory floor price</p></Col>
                  </Row>
                </Col>
              </Carousel.Item>
              <Carousel.Item>
                <Col>
                  <Row>
                    <Col className="p-0 m-0" style={{ height: "120px" }}><Image className="mx-auto d-block" src="/pages/images/home/Feature-Icon-rewards.webp" fluid /></Col>
                  </Row>
                  <Row>
                    <Col className="text-center pb-1"><span className="BuildCardsBold">Rewards</span></Col>
                  </Row>
                  <Row>
                    <Col className="text-center px-5"><p className="BuildRacingTeams-p">Get multiple rewards by owning a NFT role, as well as team rewards </p></Col>
                  </Row>
                </Col>
              </Carousel.Item>
              <Carousel.Item>
                <Col>
                  <Row>
                    <Col className="p-0 align-items-center d-flex" style={{ height: "120px" }}><Image className="mx-auto d-block" src="/pages/images/home/Feature-Icon-more.webp" fluid /></Col>
                  </Row>
                  <Row>
                    <Col className="text-center pb-1"><span className="BuildCardsBold">More</span></Col>
                  </Row>
                  <Row>
                    <Col className="text-center px-5"><p className="BuildRacingTeams-p">Being part of NEO means you get acces to NEO-Fest aswell as other cool rewards</p></Col>
                  </Row>
                </Col>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
        <Row className="my-5 my-lg-5">  
          {/* ON LARGE SCREEN */}
          <Col className="d-none d-md-flex justify-content-end">
          <a href="#RoleInformation"><Button onClick="document.getElementById('RoleInformation').scrollIntoView();" className="yellow-button" style={{width: "195px"}}>Explore roles</Button></a>
          </Col>
          <Col className="d-none d-md-flex justify-content-start">
            <Button disabled={dis_state} style={{ textDecoration: "none", lineHeight: "34px", width: "195px" }} href="#VoucherPurchase" variant="light" className="white-button">Get your voucher</Button>
          </Col>
          {/* ON MOBILE */}
          <Col xs="12" className="d-block d-md-none" align="center">
          <a href="#RoleInformation"><Button className="yellow-button w-100" >Explore roles</Button></a>
          </Col>
          <Col xs="12" className="my-3 d-block d-md-none" align="center">
            <Button disabled={dis_state} href="#VoucherPurchase" style={{textDecoration:"none", lineHeight:"30px"}} variant="light" className="white-button w-100">Get your voucher</Button>
          </Col>
        </Row>
      </Container>
      <Row id="BuildRacingTeams" className='pb-5 pt-5'>

</Row>
    </Container>
    </div>
  )
}
